import React, { useState } from 'react';
import styled from 'styled-components';
import GlobalStyle from './__Global.style';
import Intro from './_BoxIntro';
import Loading from './_Loading';
import PosterLogo from 'imgs/zjy.logo.png';

const Main = styled.main`
  max-width: 40rem;
  background: #f4f3f4;
  margin: 0 auto;
`;
const MIndex = () => {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 2000);
  return (
    <Main>
      <GlobalStyle />
      <Loading loading={loading} logo={PosterLogo} />
      <Intro />
    </Main>
  );
};

export default MIndex;
