import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CardBg from 'imgs/m/cars.line.png';
import PosterLogo from 'imgs/zjy.logo.png';
import PlayBtn from 'imgs/play.btn.png';
import DotsRectImg from 'imgs/m/dots.rect.png';
import VideoModal from './__BoxVideoModal';
import Header from './__Header';

const Section = styled.section`
  background-color: #fff;
  background-image: url(${CardBg});
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 4.1rem 3.4rem 7.5rem 3.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 100vh;
  .halfCircle {
    position: absolute;
    bottom: 6.4rem;
    right: -9.2rem;
    width: 18.4rem;
    height: 18.4rem;
    background: #f2e2f5;
    border-radius: 50%;
  }
  .dots {
    position: absolute;
    &.rect {
      width: 8rem;
      top: 21.5rem;
      right: 0.6rem;
      z-index: 1;
    }
  }
  .header {
    margin-bottom: 2.9rem;
  }
  .content {
    line-height: 1.8;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    .video {
      margin-bottom: 2.4rem;
      z-index: 2;
      position: relative;
      height: 19rem;
      .poster {
        position: absolute;
        background-color: #490f84;
        z-index: 999;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 13px;
        box-shadow: 0px 10px 15px 0px rgba(45, 30, 55, 0.15);
        .btn {
          position: absolute;
          width: 6rem;
        }
        > img {
          width: 14rem;
        }
      }
    }
  }
`;
const BoxIntro = () => {
  const [playing, setPlaying] = useState(false);
  const handlePlay = () => {
    setPlaying(true);
  };
  useEffect(() => {
    document.title = '紫荆云智慧盒子开箱、安装与激活';
  }, []);
  return (
    <Section>
      <Header header={['👇点击播放👇', '紫荆云智慧盒子开箱、安装与激活']} />
      <img src={DotsRectImg} className="dots rect" alt="矩形逗点" />
      <div className="halfCircle" />
      <div className="content">
        <div className="video">
          {!playing && (
            <div className="poster" onClick={handlePlay}>
              <img src={PosterLogo} alt="poster logo" />
              <img className="btn" src={PlayBtn} alt="play btn" />
            </div>
          )}
          <VideoModal visible={playing} setVisible={setPlaying} />
        </div>
      </div>
    </Section>
  );
};

export default BoxIntro;
