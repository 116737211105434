import React, { useEffect } from 'react';
import styled from 'styled-components';
import LogoImg from 'imgs/zj/logo.top.png';

const Wrapper = styled.section`
  z-index: 999;
  background: #8709a1;
  width: 100%;
  height: ${({ loading }) => (loading ? `100vh` : '0')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: height 1s ease-out;
  > img {
    width: 10rem;
  }
  .loading,
  > img {
    opacity: ${({ loading }) => (loading ? 1 : 0)};
    transition: opacity 1s ease-out;
  }
  .loading {
    margin-top: 2rem;
  }

  .loading-dot {
    float: left;
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 4px;
    background: #fff;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }

  .loading-dot:nth-child(1) {
    animation-delay: 0s;
  }

  .loading-dot:nth-child(2) {
    animation-delay: 0.1s;
  }

  .loading-dot:nth-child(3) {
    animation-delay: 0.2s;
  }

  .loading-dot:nth-child(4) {
    animation-delay: 0.3s;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
`;
const Loading = ({ loading = true, logo = LogoImg }) => {
  useEffect(() => {
    document.body.classList.add('disableScroll');
  }, []);
  useEffect(() => {
    if (!loading) {
      document.body.classList.remove('disableScroll');
    }
  }, [loading]);
  return (
    <Wrapper loading={loading}>
      <img src={logo} alt="loading logo" />
      <div className="loading">
        <div className="loading-dot" />
        <div className="loading-dot" />
        <div className="loading-dot" />
        <div className="loading-dot" />
      </div>
    </Wrapper>
  );
};

export default Loading;
