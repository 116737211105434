import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CloseIcon from 'imgs/close.mask.png';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  z-index: 998;
  justify-content: center;
  align-items: center;
  display: none;
  &.visible {
    display: flex;
  }
  .modal {
    z-index: 999;
    video {
      width: 100%;
    }
  }
  .close {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 3rem;
    height: 3rem;
    background: url(${CloseIcon});
    background-size: cover;
  }
`;

const VideoModal = ({ visible = false, setVisible = () => {} }) => {
  const video = useRef(null);
  useEffect(() => {
    const vEle = video.current;
    if (visible) {
      console.log(video.current);
      vEle.currentTime = 0;
      vEle.play();
      document.documentElement.classList.add('lock');
    } else {
      vEle.pause();
      document.documentElement.classList.remove('lock');
    }
  }, [visible]);
  const handleClose = () => {
    setVisible(false);
  };
  return (
    <Wrapper className={visible ? 'visible' : ''}>
      <i onClick={handleClose} className="close" />
      <div className="modal">
        <video ref={video} controls src="https://v001.1d1d100.com/zijing/zijingbox.mp4" />
      </div>
    </Wrapper>
  );
};

export default VideoModal;
