import React from 'react';
import styled from 'styled-components';
import DotsImg from 'imgs/m/dots.header.png';

const Wrapper = styled.div`
  .dots {
    position: absolute;
    top: 4.6rem;
    width: 3.8rem;
    &.left {
      left: 0.3rem;
    }
    &.right {
      right: 0.3rem;
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: #fdc904 0px -0.8em inset;
    margin-bottom: 2.9rem;
    line-height: 1.4;
    > span {
      color: #1a1a1a;
      font-weight: 800;
      font-size: 1.5rem;
    }
  }
`;

const Header = ({ header = '默认标题' }) => {
  return (
    <Wrapper>
      <img src={DotsImg} className="dots left" alt="左侧逗点" />
      <img className="dots right" src={DotsImg} alt="右侧逗点" />
      <h1 className="header">
        {Array.isArray(header) ? header.map(h => <span key={h}>{h}</span>) : <span>{header}</span>}
      </h1>
    </Wrapper>
  );
};

export default Header;
